var site = site || {};
site.Videos = (() => {
    // Functions

    const ytVideos = () => {
        const videos = document.querySelectorAll('.js-yt-video');

        videos.forEach(function(el){
            new YT.Player(el.id, {
                playerVars: {
                    'modestBranding': 1,
                    'autoplay': 1,
                    'rel': 0,
                    'showinfo': 0,
                    'autohide': 1
                },
                events: {
                    // call this function when player is ready to use
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            });
        })
        
    }

    const onPlayerReady = function(event){
        const videoTrigger = event.target.g.parentElement.parentElement.previousElementSibling;
        const videoKeyboardTrigger = videoTrigger.querySelector('.js-video-kb-trigger');

        videoTrigger.addEventListener('click', function(){            
            this.classList.add('is-hidden');
            this.nextElementSibling.classList.add('z-index-4');
            event.target.playVideo();
        })

        videoKeyboardTrigger.addEventListener('keypress', function(e){
            if (e.key === 'Enter') {
                this.parentElement.classList.add('is-hidden');
                this.parentElement.nextElementSibling.classList.add('z-index-4');
                event.target.playVideo();
            }
        })
    }

    const onPlayerStateChange = function(event) {
        if(event.data == 2) {
            const videoContainer = event.target.g.parentElement.parentElement;
            const videoTrigger = videoContainer.previousElementSibling;

            videoContainer.classList.remove('z-index-4');
            videoTrigger.classList.remove('is-hidden');
        }
    }

    // Return
    return {
        ytVideos : ytVideos
    };
})();
